.fixed-sidebar {
  width: 17.25rem;
  height: 62.4rem;
  position: fixed;
  z-index: 1;
  top: 5.3rem;
  left: 4.7rem;
  background: #ffffff;
  border-radius: 10px;
  overflow-x: hidden;
  padding: 8px 0;
}

.description{
  width: 15.2rem;
height: 1.75rem;

font-family: 'SF UI Display Normal';
font-style: normal;
font-size: 12px;
line-height: 20px;

color: #000000;
}

.success-badge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 14px 16px;
  gap: 10px;

  width: 16rem;
  height: 2.8rem;

  background: #e4ffe9;
  border-radius: 30px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.badge-text{
  font-family: 'SF UI Display Medium';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;

text-align: center;

color: #338540;
}


.shop-nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 14px 16px 14px 24px;
  gap: 10px;
  isolation: isolate;
  transition: color 0.2s;
  width: 260px;
  height: 52px;
  flex: none;
  order: 0;
  flex-grow: 0;
  transition: color 0.2s;
  margin-top: 3px;
  text-decoration: none;
  color: #000000;
}

.shop-menu-item{
  text-decoration: none;
}



.shop-nav:hover {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 14px 16px 14px 24px;
  gap: 10px;
  isolation: isolate;

  width: 260px;
  height: 52px;

  /* Gift box */

  background: linear-gradient(127.18deg, #338540 -10.82%, #034a0f 109.06%);
  border-radius: 10px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  transition: color 0.2s;
  /* margin-top: 9px; */
  text-decoration: none;
  color: #ffffff;
  position: relative;
}

.shop-nav-active .nav-inner{
  position: absolute;
width: 22px;
height: 22px;
left: 0px;
top: 10px;
display: inline;
}

.shop-nav-active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 14px 16px 14px 0px;
  gap: 10px;
  isolation: isolate;

  width: 260px;
  height: 52px;

  /* Gift box */

  background: linear-gradient(127.18deg, #338540 -10.82%, #034a0f 109.06%);
  border-radius: 10px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  transition: color 0.2s;
  /* margin-top: 9px; */
  text-decoration: none;
  color: #ffffff;
  position: relative;
}


.nav-inner{
  display: none;
}

.shop-nav-active span{
  color: #ffffff;
}