/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 1.0rem;
  height: 1.0rem;
  left: 6%;
  top: 1.8rem;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #fbfbfb;
  padding: 2.5em 0.3em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #ffffff;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.bm-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 14px 16px 14px 24px;
  gap: 10px;
  isolation: isolate;
  transition: color 0.2s;
  width: 260px;
  height: 52px;
  flex: none;
  order: 0;
  flex-grow: 0;
  transition: color 0.2s;
  margin-top: 3px;
  text-decoration: none;
  color: #000000;
}

/* Individual item */
.bm-item:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 14px 16px 14px 24px;
  gap: 10px;
  isolation: isolate;

  width: 260px;
  height: 52px;

  /* Gift box */

  background: linear-gradient(127.18deg, #338540 -10.82%, #034a0f 109.06%);
  border-radius: 10px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  transition: color 0.2s;
  /* margin-top: 9px; */
  text-decoration: none;
  color: #ffffff;
}

.nav-active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 14px 16px 14px 24px;
  gap: 10px;
  isolation: isolate;

  width: 260px;
  height: 52px;

  /* Gift box */

  background: linear-gradient(127.18deg, #338540 -10.82%, #034a0f 109.06%);
  border-radius: 10px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  transition: color 0.2s;
  /* margin-top: 9px; */
  text-decoration: none;
  color: #ffffff;
  position: relative;
}

.active span{
  color: #ffffff;
}



.bm-item:hover {
  color: #ffffff;
}



.nav-active .nav-inner{
  position: absolute;
width: 22px;
height: 22px;
left: 0px;
top: 10px;
display: inline;
}


.nav-inner{
  display: none;
}